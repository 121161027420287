import { ITNToast, TNTreeProps } from "@life_uikit/uikit/interfaces";
import { ReasonForAbsence } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/absence_pb";
import { UserStatus } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/user_status_pb";

import { IPopup } from "@/components/popup/i-popup";
import { IServiceUUIDs } from "@/components/sidebar/i-sidebar";
import { INotificationsStore } from "@/store/modules/notifications/i-notifications";
import { ISettingsStore } from "@/store/modules/settings/i-settings";
import { IMessage, IStreamStore } from "@/store/modules/stream/i-stream";
import { IWidget } from "@/views/homepage/i-homepage";
import { IApp, ISite } from "@/views/services/i-services";

export interface IState {
  user: IProfile | null;
  toasts: ITNToast[];
  timezones: ITimezone[];
  widgetList: IWidget[];
  popupList: IPopup[];
  serviceList: IApp[] | null;
  siteList: ISite[] | null;
  fileList: {
    uuid: string;
    name: string;
    base?: string;
    url?: string;
  }[];
  tutorialTriggerFunction: Function | null;
  serviceUuids: IServiceUUIDs;
  contactList: IUser[] | null;
  contactListLoad: boolean;
  chatScrollPosition: {
    uuid: string;
    position: number;
  }[];
  currentChatId: string | null;
  isAppFocused: boolean;
  shareFile: File | null;
  isShareFileFile: boolean;
  forwardMessage: IMessage | null;
  colleagues: IUser[] | null;
  feedbackForm: boolean | { type: string; context?: string };
  isMobile: boolean;
  mobileWindowHeight: number;
  pageStates: IPageState;
  runningApps: IRunningApp[];
  incomingCallWindows: IIncomingCall[];
  waitingCallWindow: IIncomingCall | null;
  isBrowser: boolean;
  isOnline: boolean;
  isWaitingCallRejected: boolean;
  forceLeavePageHandler: Function | null;
  updateStatus: {
    actual: string;
    appUrl: string;
    force: boolean;
  } | null;
  lastUpdate: number;
  colleaguesLoad: boolean;
  emailStatus: EmailStatus;
  deviceID: string;
}

export interface IStore extends IState {
  streamStore: IStreamStore;
  notificationsStore: INotificationsStore;
  settingsStore: ISettingsStore;
}

export interface IAbsence {
  from: Date;
  to: Date;
  reason: ReasonForAbsence;
  otherReason?: string;
  replacementPerson?: IUser;
}

export interface IColleagues {
  funcLeader?: IUser;
  admLeader?: IUser;
  subordinates: IUser[];
  coworkers: IUser[];
}

export interface IProfile extends IUser {
  employeeEmail?: string;
}

export interface IUser extends IColleagues {
  id: string;
  profileId: string;
  username: string;
  renderName: string;
  firstName: string;
  status: UserStatus;
  avatar?: IAvatar;
  isBot?: boolean;
  portalCode: number;
  department: string;
  position: string;
  email?: string;
  phoneList: {
    title: string;
    number: string;
  }[];
  isChannelNotMy?: boolean;
  interlocutorId?: string;
  isStream?: boolean;
  meetStream?: boolean;
  birthdate?: Date;
  absence: IAbsence[];
  location?: string;
  isSuperChannel?: boolean;
}

export interface IAvatar {
  id?: string;
  url?: string;
  name: string;
}

export type IPageState = Record<string, IPageStateItem | undefined>;

export interface IPageStateItem {
  value: string;
  time: number;
  params?: Record<string, any>;
}

export interface IIncomingCall {
  subject: string;
  room: string;
  group?: boolean;
  participantCount: number;
  participants: {
    name: string;
    avatarUrl: string;
  }[];
}

export interface IRunningApp {
  id: string;
  opened: number;
  query: Record<string, string>;
}

type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> &
  Partial<Type>;

export type IMutationToast = MakeOptional<
  ITNToast,
  "duration" | "creationTime"
> & {
  duration?: number;
};
export type IMutationPopup = MakeOptional<IPopup, "index">;

export interface ITimezone extends TNTreeProps.Option {
  diff: number;
  enLocale: string;
  id: number;
}

/* eslint-disable no-unused-vars */
export enum EmailStatus {
  NotSet,
  NotConfirmed,
  Confirmed
}
