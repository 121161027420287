import { ITNDropdownMenu } from "@life_uikit/uikit/interfaces";
import { Stream as OuterStream } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/stream_pb";
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import {
  hexColorRegex,
  imageExtensionArray,
  linkRegex,
  linkRegexLocalhost,
  nullUuid,
  uuidRegex
} from "@/common/constants";
import Avatar from "@/components/avatar/avatar";
import DropdownMenu from "@/components/dropdown-menu/dropdown-menu.vue";
import ImagePreviewer from "@/components/image-previewer/image-previewer.vue";
import { IPopup, IPopupButton } from "@/components/popup/i-popup";
import Popup from "@/components/popup/popup.vue";
import { IServiceFile, SplashState } from "@/components/service/i-service";
import { useConfirmEmail } from "@/composables/confirm-email";
import { useError } from "@/composables/error";
import { useInfrastructure } from "@/composables/infrastructure";
import { useService } from "@/composables/service";
import {
  IBridgeRequest,
  IBridgeResponse
} from "@/infrastructure/bridge/i-bridge";
import { Electron } from "@/infrastructure/electron";
import { key } from "@/store";
import { IAttachment, Stream } from "@/store/modules/stream/i-stream";
import { IApp } from "@/views/services/i-services";

import Type = OuterStream.Type;

export default defineComponent({
  name: "Service",
  components: {
    Avatar,
    DropdownMenu,
    Popup,
    ImagePreviewer
  },
  props: {
    id: { required: true, type: String },
    visible: { required: true, type: Boolean },
    query: { type: Object, default: () => {} }
  },
  emits: ["clearQuery", "updateQuery"],
  setup: (props, { emit }) => {
    const $router = useRouter();
    const $route = useRoute();
    const $store = useStore(key);
    const $infra = useInfrastructure();
    const { $service, $streamService, $environmentService } = useService();
    const $error = useError();
    const {
      isConfirmPopupVisible,
      confirmPopup,
      openEmailConfirmPopup,
      closeEmailConfirmPopup
    } = useConfirmEmail(close => {
      closeEmailConfirmHandler(close);
    });

    const url = ref<string>("");
    const app = ref<IApp | null>(null);
    const iframe = ref<HTMLIFrameElement | null>(null);
    const isOptionsMenuVisible = ref<boolean>(false);
    const fileInput = ref<HTMLInputElement | null>(null);
    const filePicker = ref<{
      isShowPopup: boolean;
      isMultipleFiles: boolean;
      isImagesOnly: boolean;
    }>({
      isShowPopup: false,
      isMultipleFiles: false,
      isImagesOnly: false
    });
    const selectedFiles = ref<IServiceFile[]>([]);
    const isFilesUploading = ref<boolean>(false);
    const fileDOMItems = ref<HTMLElement[] | null>(null);
    const previewImageList = ref<IAttachment[]>([]);
    const previewPdf = ref<boolean>(false);
    const isFileDragging = ref<boolean>(false);
    const payload = ref<string>("");
    const splashLoadState = ref<SplashState>(SplashState.Visible);
    const waitForAnswer = ref<boolean>(false);
    const noAnimation = ref<boolean>(false);
    const showNoAccessWindow = ref<boolean>(false);
    const showErrorWindow = ref<boolean>(false);
    const splashTimeout = ref<NodeJS.Timeout | null>(null);
    const loaded = ref<boolean>(false);
    const dragDropLabel = ref<HTMLElement | null>(null);
    const maxFiles = ref<number>(1);
    const isSetUrlPopupVisible = ref<boolean>(false);
    const urlText = ref<string>("");
    const buttonsBackgroundColor = ref<string>("");
    const buttonsIconColor = ref<string>("");
    const buttonsDividerColor = ref<string>("");

    let noReload: boolean = false;
    const waitResponseDuration = 10000;
    const imageExtensions = imageExtensionArray.join(",");
    let pendingBridgeRequest: IBridgeRequest | null = null;
    let dragStopTimeout: NodeJS.Timeout | null = null;
    const dragEvents = ["dragenter", "dragover", "dragleave", "drop"];

    onMounted(async () => {
      await setApp(true);
      if (app.value) {
        $service.setPageTitle(app.value.title);
      }
      splashTimeout.value = setTimeout(() => {
        showErrorWindow.value = true;
        splashLoadState.value = SplashState.Loaded;
        splashTimeout.value = setTimeout(() => {
          splashLoadState.value = SplashState.Hidden;
          splashTimeout.value = setTimeout(() => {
            noAnimation.value = true;
          }, 300);
        }, 300);
      }, waitResponseDuration);
      window.addEventListener("message", sendCatcher);
      dragEvents.forEach(event => {
        document.body.addEventListener(event, preventDefaults);
      });
    });
    onBeforeUnmount(() => {
      window.removeEventListener("message", sendCatcher);
      dragEvents.forEach(event => {
        document.body.removeEventListener(event, preventDefaults);
      });
    });

    const optionsMenuItems = computed<ITNDropdownMenu[]>(() => {
      const list: ITNDropdownMenu[] = [
        {
          title: "Перезагрузить мини-приложение",
          id: "reload"
        }
      ];

      if (!isBrowser.value) {
        list.unshift({
          title: "Сбросить куки и кэш",
          id: "clear-cache"
        });
      }

      if (devMode.value) {
        list.push({
          title: "Задать ссылку",
          accent: true,
          id: "set-url"
        });
      }

      return list;
    });
    const isFilesHaveErrors = computed<boolean>(() => {
      return selectedFiles.value.some(file => file.error);
    });
    const isUploadFilesButtonDisabled = computed<boolean>(() => {
      return (
        !selectedFiles.value.length ||
        selectedFiles.value.length > maxFiles.value ||
        isSomeFileTooBig.value ||
        isFilesUploading.value
      );
    });
    const uploadLabel = computed<string>(() => {
      return (
        "Выберите " +
        (filePicker.value.isImagesOnly
          ? filePicker.value.isMultipleFiles
            ? "изображения"
            : "изображение"
          : filePicker.value.isMultipleFiles
            ? "файлы"
            : "файл")
      );
    });
    const uploadText = computed<string>(() => {
      return (
        "или перетащите " +
        (filePicker.value.isMultipleFiles ? "их" : "его") +
        " в эту область"
      );
    });
    const uploadReleaseText = computed<string>(() => {
      const many = filePicker.value.isMultipleFiles;

      return (
        "Отпустите файл" +
        (many ? "ы" : "") +
        " чтобы " +
        (many ? "их" : "его") +
        " добавить"
      );
    });
    const maxFilesLabel = computed<string>(
      () =>
        `${maxFiles.value} ${$service.declOfNum(maxFiles.value, [
          "файл",
          "файла",
          "файлов"
        ])}`
    );
    const devMode = computed<boolean>(
      () => $store.state.settingsStore.developerMode
    );
    const isCustomService = computed<boolean>(() =>
      $store.state.settingsStore.customServiceList.some(
        s => s.uuid === props.id
      )
    );
    const setUrlPopup = computed<IPopup>(() => ({
      body: "<p style='padding-bottom: 16px;'>Введите ссылку на приложение для его отладки.<br/>Оставьте поле пустым, чтобы сбросить задание ссылки.</p>",
      title: "Ссылка на приложение",
      index: 0,
      buttonsLayout: "horizontal",
      buttons: [
        ...(isCustomService.value
          ? [
              {
                text: "Сбросить",
                type: "outline",
                callback: () => {
                  setUrl(true);
                }
              } as IPopupButton
            ]
          : []),
        {
          text: "Задать",
          type: "primary",
          callback: () => {
            setUrl(true);
          }
        }
      ]
    }));
    const isSomeFileTooBig = computed<boolean>(() => {
      return selectedFiles.value.some(f => f.warn);
    });
    const maxFileSize = computed<string>(() =>
      $service.prettifyFileSize($store.state.settingsStore.maxServiceFileSize)
    );
    const tooBigFilesText = computed<string>(() => {
      if (maxFiles.value > 1) {
        return `Некоторые ${
          filePicker.value.isImagesOnly ? "изображения" : "файлы"
        } слишком большие. Максимальный размер -
          ${maxFileSize.value}`;
      }

      return `${
        filePicker.value.isImagesOnly
          ? "Изображение слишком большое"
          : "Файл слишком большой"
      }. Максимальный размер -
          ${maxFileSize.value}`;
    });
    const loadFilesErrorText = computed<string>(() => {
      if (maxFiles.value > 1) {
        return `Некоторые ${
          filePicker.value.isImagesOnly ? "изображения" : "файлы"
        } не загрузились. Попробуйте ещё раз.`;
      }

      return `${
        filePicker.value.isImagesOnly ? "Изображение" : "Файл"
      } не загрузился. Попробуйте ещё раз.`;
    });
    const isBrowser = computed<boolean>(() => $store.state.isBrowser);
    const filePickerPopup = computed<IPopup>(() => ({
      title:
        "Загрузка " +
        (filePicker.value.isImagesOnly
          ? maxFiles.value > 1
            ? "изображений"
            : "изображения"
          : maxFiles.value > 1
            ? "файлов"
            : "файла"),
      text: maxFiles.value > 1 ? filePickerText.value : "",
      buttons: [
        {
          text: "Загрузить",
          loading: isFilesUploading.value,
          disabled: isUploadFilesButtonDisabled.value,
          callback: uploadFiles
        }
      ]
    }));
    const filePickerText = computed<string>(
      () =>
        `Максимальное количество ${
          filePicker.value.isImagesOnly ? "изображений" : "файлов"
        }: ${maxFiles.value}.`
    );
    const isMobile = computed<boolean>(() => $store.state.isMobile);

    watch(
      () => $route.params.id,
      async () => {
        if (app.value && $route.params.id === app.value?.uuid) {
          $service.setPageTitle(app.value.title);
        }
      }
    );
    watch(
      () => props.query.payload || "",
      (to, from) => {
        if (noReload) {
          return;
        }
        if (!from && to && props.id === $route.params.id) {
          setApp(true, true);
        }
      }
    );
    watch(
      () => props.query.url || "",
      (to, from) => {
        if (!from && to && props.id === $route.params.id) {
          setApp(true, true);
        }
      }
    );
    watch(
      () => props.query.reload || "",
      to => {
        if (to && to === "true" && props.id === $route.params.id) {
          setApp(true, true);
        }
      }
    );

    const clearQueryParams = () => {
      emit("clearQuery");
    };
    const setApp = async (init = false, reload = false) => {
      if (init) {
        payload.value = props.query.payload
          ? decodeURIComponent(props.query.payload as string)
          : "";
      }
      if (reload) {
        url.value = "";
        await nextTick();
      }
      buttonsBackgroundColor.value = "";
      buttonsIconColor.value = "";
      buttonsDividerColor.value = "";
      app.value =
        ($store.state.serviceList || []).find(
          appItem => appItem.uuid === props.id
        ) || null;
      const siteItem = ($store.state.siteList || []).find(
        s => s.uuid === props.id
      );

      if ((app.value && app.value.siteAsService) || siteItem) {
        let url: string = (app.value ? app.value.source : siteItem?.url) || "";
        const needsAuth: boolean =
          (app.value ? app.value.needsAuth : siteItem?.needsAuth) || false;
        const uuid: string =
          (app.value ? app.value.uuid : siteItem?.uuid) || nullUuid;

        if (needsAuth) {
          try {
            url = await $infra.services.GetSiteUrlWithAuthCode(uuid);
          } catch (error) {
            $error({
              message: "Ошибка авторизации для открытия сервиса",
              details: JSON.stringify(error)
            });

            return;
          }
        }

        if (isBrowser.value) {
          window.open(url, "_blank")!.focus();
        } else {
          Electron().shell.openExternal(url);
        }
        closeService();
      } else if (props.query.url && app.value) {
        url.value = app.value.source.replace(/\/$/, "") + props.query.url;
      } else if (
        devMode.value &&
        $store.state.settingsStore.customServiceList.some(
          s => s.uuid === props.id
        )
      ) {
        app.value =
          $store.state.settingsStore.customServiceList.find(
            appItem => appItem.uuid === props.id
          ) || null;
        url.value = app.value?.source || "";
      } else if (app.value) {
        url.value = app.value.source;
      }
      clearQueryParams();
    };
    const bridgeHandler = async (data: IBridgeRequest, origin: string) => {
      const user = $store.state.user;

      if (app.value) {
        if (data.type === "LifeMiniAppAuthCode") {
          $infra.bridge.AuthCode(data, app.value.uuid).then(response => {
            if (
              response.data.error?.message ===
              "ProfileHasNoRequiredFields: email"
            ) {
              pendingBridgeRequest = data;
              openEmailConfirmPopup();

              return;
            }
            sendResponse(response);
          });
        } else if (data.type === "LifeMiniAppInit") {
          if (!waitForAnswer.value && !loaded.value) {
            setTimeout(() => {
              if (!waitForAnswer.value) {
                if (splashTimeout.value) {
                  clearTimeout(splashTimeout.value);
                }
                noAnimation.value = false;
                waitForAnswer.value = true;
                splashLoadState.value = SplashState.Loaded;
                splashTimeout.value = setTimeout(() => {
                  splashLoadState.value = SplashState.Hidden;
                  splashTimeout.value = setTimeout(() => {
                    noAnimation.value = true;
                    waitForAnswer.value = false;
                    loaded.value = true;
                  }, 300);
                }, 300);
              }
            }, 100);
          }
          $infra.bridge.Init(data, payload.value).then(response => {
            sendResponse(response);
          });
        } else if (data.type === "LifeMiniAppShowSplash") {
          noAnimation.value = false;
          await nextTick();
          splashLoadState.value = SplashState.Visible;
          showNoAccessWindow.value = false;
          waitForAnswer.value = true;
          if (splashTimeout.value) {
            clearTimeout(splashTimeout.value);
          }
          sendResponse({
            type: data.type + "Result",
            requestId: data.requestId,
            data: {
              result: true
            }
          });
        } else if (data.type === "LifeMiniAppHideSplash") {
          const splashVisible = splashLoadState.value !== SplashState.Hidden;

          if (splashVisible) {
            if (splashTimeout.value) {
              clearTimeout(splashTimeout.value);
            }
            splashLoadState.value = SplashState.Loaded;
            splashTimeout.value = setTimeout(() => {
              splashLoadState.value = SplashState.Hidden;
              splashTimeout.value = setTimeout(() => {
                noAnimation.value = true;
                loaded.value = true;
              }, 300);
            }, 300);
          }
          sendResponse({
            type: data.type + "Result",
            requestId: data.requestId,
            data: {
              result: splashVisible
            }
          });
        } else if (data.type === "LifeMiniAppNoAccess") {
          if (splashTimeout.value) {
            clearTimeout(splashTimeout.value);
          }
          splashLoadState.value = SplashState.Hidden;
          showNoAccessWindow.value = true;
          splashTimeout.value = setTimeout(() => {
            noAnimation.value = true;
          }, 300);
          sendResponse({
            type: data.type + "Result",
            requestId: data.requestId,
            data: {
              result: true
            }
          });
        } else if (data.type === "LifeMiniAppGetClientVersion") {
          $infra.bridge.GetClientVersion(data).then(response => {
            sendResponse(response);
          });
        } else if (data.type === "LifeMiniAppPickFiles") {
          pendingBridgeRequest = data;
          filePicker.value.isMultipleFiles = true;
          if (data.data && data.data.type === 1) {
            filePicker.value.isImagesOnly = true;
          }
          maxFiles.value = data.data.max_files;
          await openFilePickerPopup();
        } else if (data.type === "LifeMiniAppPickFile") {
          pendingBridgeRequest = data;
          if (data.data && data.data.type === 1) {
            filePicker.value.isImagesOnly = true;
          }
          maxFiles.value = 1;
          filePicker.value.isMultipleFiles = false;
          await openFilePickerPopup();
        } else if (data.type === "LifeMiniAppTakePicture") {
          pendingBridgeRequest = data;
          filePicker.value.isImagesOnly = true;
          await openFilePickerPopup();
        } else if (data.type === "LifeMiniAppCopyText") {
          $infra.bridge.CopyText(data).then(response => {
            sendResponse(response);
          });
        } else if (data.type === "LifeMiniAppGetAppLink") {
          $infra.bridge.GetAppLink(data, app.value.uuid).then(response => {
            sendResponse(response);
          });
        } else if (data.type === "LifeMiniAppSupports") {
          $infra.bridge.Supports(data).then(response => {
            sendResponse(response);
          });
        } else if (data.type === "LifeMiniAppOpenUrl") {
          let proceed = true;

          if (!data.data.use_external_browser) {
            proceed = await urlHandler(data.data.url);
          }
          if (proceed) {
            $infra.bridge.OpenUrl(data).then(response => {
              sendResponse(response);
            });
          }
        } else if (data.type === "LifeMiniAppOpenStream") {
          if (
            data.data.stream_id &&
            $store.state.streamStore.streamList.some(
              s => s.id === data.data.stream_id
            )
          ) {
            await $router.push({
              name: "chat-page",
              params: {
                id: data.data.stream_id
              }
            });
          } else if (user) {
            try {
              const streamResponse = await $infra.stream.GetStream({
                streamId: data.data.stream_id
              });

              await $infra.stream.Subscribe(streamResponse.id);
              setTimeout(() => {
                $router.push({
                  name: "chat-page",
                  params: {
                    id: data.data.stream_id
                  }
                });
              }, 300);
            } catch (e: any) {
              if (
                e &&
                typeof e.message === "string" &&
                e.message.includes("PermissionDenied")
              ) {
                await $router.push({
                  name: "chat-page",
                  params: {
                    id: data.data.stream_id
                  }
                });

                return;
              }
              sendResponse({
                type: "LifeMiniAppOpenStreamFailed",
                data: {
                  error: {
                    code: 0,
                    message: e.message
                      ? e.message
                      : "Невозможно подписаться на чат"
                  }
                },
                requestId: data.requestId
              });
            }
          }
        } else if (data.type === "LifeMiniAppOpenDirectStream") {
          const checkStream = (stream?: Stream) => {
            if (!stream || stream.interlocutorId === nullUuid) {
              throw new Error();
            }
          };
          const stream = $store.state.streamStore.streamList.find(
            s => s.interlocutorId === data.data.user_id
          );

          if (!data.data.user_id || !uuidRegex.test(data.data.user_id)) {
            sendResponse({
              type: "LifeMiniAppOpenDirectStreamFailed",
              data: {
                error: {
                  code: 0,
                  message: "Неверный формат UUID"
                }
              },
              requestId: data.requestId
            });

            return;
          }

          if (stream) {
            await $router.push({
              name: "chat-page",
              params: {
                id: stream.id
              }
            });
          } else if (user) {
            try {
              const streamResponse = await $infra.stream.CreateStream({
                type: Type.DIRECT,
                usersIds: [data.data.user_id],
                title: "",
                description: "",
                isPublic: true,
                profile: true
              });

              const stream = $streamService.streamsAdapter(
                streamResponse,
                user,
                $store.state.streamStore.users,
                $store.state.contactList || []
              );

              checkStream(stream);

              const messageResponse = await $infra.stream.GetMessageList({
                streamId: streamResponse.id,
                updateDate: new Date()
              });

              if (messageResponse.length) {
                const messages = messageResponse.map(m =>
                  $streamService.messageAdapter(
                    m,
                    user.id,
                    $store.state.streamStore.users,
                    user,
                    $store.state.contactList || [],
                    stream
                  )
                );
                const lastMessage = messages[0];

                stream.messages = messages;
                stream.lastMessageId = lastMessage.id;
                stream.lastMessageAuthorName =
                  $streamService.getLastMessageAuthor(
                    stream.type,
                    stream.interlocutorId,
                    lastMessage,
                    $store.state.streamStore.users,
                    $store.state.user,
                    $store.state.contactList || []
                  );
                stream.lastMessageBody =
                  $streamService.getChatBody(lastMessage);
                stream.lastMessageDate = lastMessage.date;
                stream.lastMessageDateString = $streamService.getChatTime(
                  stream.createDate,
                  lastMessage.date
                );
                stream.lastReadMessageId = lastMessage.id;
              }

              await $store.dispatch("streamStore/appendStream", stream);

              await $router.push({
                name: "chat-page",
                params: {
                  id: stream.id
                }
              });
            } catch (e) {
              sendResponse({
                type: "LifeMiniAppOpenDirectStreamFailed",
                data: {
                  error: {
                    code: 0,
                    message: "Ошибка при открытии чата"
                  }
                },
                requestId: data.requestId
              });
            }
          }
        } else if (data.type === "LifeMiniAppOpenFile") {
          if (!(data.data.url || "").match(linkRegex)) {
            sendResponse({
              type: data.type + "Failed",
              data: {
                error: {
                  code: 0,
                  message: "Param `URL` is invalid"
                }
              },
              requestId: data.requestId
            });
          } else if ([0, 2].includes(data.data.file_type)) {
            previewPdf.value = data.data.file_type === 2;
            previewImageList.value = [
              {
                name: previewPdf.value ? "документ" : "изображение",
                type: data.data.mimeType || "image/png",
                base: data.data.url,
                progress: 1,
                size: 0,
                uuid: nullUuid
              }
            ];
            sendResponse({
              type: (data.type = "Result"),
              data: {
                result: true
              },
              requestId: data.requestId
            });
          } else {
            sendResponse({
              type: data.type + "Failed",
              error: {
                code: 2,
                message: "Неверный тип файла"
              },
              requestId: data.requestId
            });
          }
        } else if (
          ["LifeMiniAppDownloadFile", "LifeMiniAppSaveToGallery"].includes(
            data.type
          )
        ) {
          $infra.bridge.Download(data).then(response => {
            sendResponse(response);
          });
        } else if (data.type === "LifeMiniAppHaptic") {
          if (!isBrowser.value && $service.isMacOS()) {
            Electron().ipcRenderer.send("haptic");
            sendResponse({
              type: "LifeMiniAppHapticResult",
              data: {
                result: true
              },
              requestId: data.requestId
            });
          } else {
            sendResponse({
              type: "LifeMiniAppHapticFailed",
              data: {
                error: {
                  code: 1,
                  message: data.type + " only works in TN Life Desktop MacOS"
                }
              },
              requestId: data.requestId
            });
          }
        } else if (data.type === "LifeSystemChangeLocation") {
          if (app.value && app.value.source.includes(origin) && props.visible) {
            void urlHandler(data.data.location, true);
          }
        } else if (data.type === "LifeSystemUnload") {
          unloadHandler();
        } else if (data.type === "LifeMiniAppSetTheme") {
          const validate = validateTheme(data.data);

          if (validate) {
            sendResponse({
              type: data.type + "Failed",
              requestId: data.requestId,
              data: {
                error: {
                  code: 0,
                  message: validate
                }
              }
            });
          } else {
            sendResponse({
              type: data.type + "Result",
              requestId: data.requestId,
              data: {
                result: true
              }
            });
          }
        } else {
          sendResponse({
            type: data.type + "Failed",
            requestId: data.requestId,
            data: {
              error: {
                code: 1,
                message: data.type + " is not supported in TN Life Desktop"
              }
            }
          });
        }
      }
    };
    const sendCatcher = (event: MessageEvent) => {
      if (typeof event.data === "string") {
        try {
          const data = JSON.parse(event.data);

          if (
            data.type &&
            props.visible &&
            (data.type.match(/^LifeMiniApp/) || data.type.match(/^LifeSystem/))
          ) {
            if (devMode.value) {
              $service.log([
                `%c${new Date().toLocaleString()}: %c${app.value?.title}%c sent message %c${data.type}`,
                "color: initial;font-style: italic",
                "color: #49a1ff;font-weight: bold",
                "color: initial;font-weight: bold",
                data.type.startsWith("LifeSystem")
                  ? "color: #e11b11;font-weight: bold"
                  : "color: #ff8a0c;font-weight: bold",
                data.data
              ]);
            }
            bridgeHandler(data, event.origin);
          }
        } catch {}
      }
    };
    const closeService = () => {
      $store.commit("closeApp", app.value?.uuid);
      $router.push({
        name: "services"
      });
    };
    const reloadService = () => {
      splashLoadState.value = SplashState.Visible;
      waitForAnswer.value = false;
      loaded.value = false;
      showNoAccessWindow.value = false;
      showErrorWindow.value = false;
      payload.value = props.query.payload as string;
      const holdUrl = url.value;

      url.value = "";
      setTimeout(() => {
        url.value = holdUrl;
        noAnimation.value = false;
        hideSplash();
      }, 20);
    };
    const clearCache = () => {
      if (!isBrowser.value) {
        Electron().ipcRenderer.send("clear-all", app.value?.source);
        Electron().ipcRenderer.send("clear-cache");
      }
      setTimeout(() => {
        reloadService();
      }, 100)
    };
    const hideSplash = () => {
      if (splashTimeout.value) {
        clearTimeout(splashTimeout.value);
      }
      splashTimeout.value = setTimeout(() => {
        showErrorWindow.value = true;
        noAnimation.value = false;
        splashLoadState.value = SplashState.Loaded;
        splashTimeout.value = setTimeout(() => {
          splashLoadState.value = SplashState.Hidden;
          splashTimeout.value = setTimeout(() => {
            noAnimation.value = true;
          }, 300);
        }, 300);
      }, waitResponseDuration);
    };
    const openOptionsMenu = () => {
      isOptionsMenuVisible.value = true;
    };
    const closeOptionsMenu = () => {
      isOptionsMenuVisible.value = false;
    };
    const optionSelectHandler = (optionValue: string) => {
      if (optionValue === "clear-cache") {
        clearCache();
      } else if (optionValue === "reload") {
        reloadService();
      } else if (optionValue === "set-url") {
        openSetUrlPopup();
      }
      closeOptionsMenu();
    };
    const uploadFiles = async () => {
      if (fileInput.value?.files) {
        isFilesUploading.value = true;
        const file = selectedFiles.value[0];

        file.error = false;
        if (!filePicker.value.isMultipleFiles) {
          $infra.bridge
            .UploadFile(file.file)
            .then(response => {
              if (pendingBridgeRequest) {
                sendResponse({
                  type: pendingBridgeRequest.type + "Result",
                  requestId: pendingBridgeRequest.requestId,
                  data: {
                    file: {
                      name: response.name,
                      mimeType: response.mime_type,
                      size: response.size,
                      url: response.url
                    }
                  }
                });
                pendingBridgeRequest = null;
                closeFilePickerPopup();
              }
            })
            .catch(() => {
              file.error = true;
            })
            .finally(() => {
              isFilesUploading.value = false;
            });
        } else {
          let errorIndex = -1;

          for (let i = 0; i < selectedFiles.value.length; i++) {
            const file = selectedFiles.value[i];

            if (errorIndex === -1 && !file.uploadedFile) {
              file.error = false;
              await $infra.bridge
                .UploadFile(file.file)
                .then(response => {
                  file.uploadedFile = response;
                })
                .catch(() => {
                  errorIndex = i;
                  file.error = true;
                });
            }
          }
          isFilesUploading.value = false;
          if (errorIndex === -1 && pendingBridgeRequest) {
            sendResponse({
              type: pendingBridgeRequest.type + "Result",
              data: {
                files: selectedFiles.value.map(file => ({
                  name: file.file.name,
                  mimeType: file.uploadedFile
                    ? file.uploadedFile.mime_type
                    : "",
                  size: file.file.size,
                  url: file.uploadedFile ? file.uploadedFile.url : ""
                }))
              },
              requestId: pendingBridgeRequest.requestId
            });
            closeFilePickerPopup();
          } else if (errorIndex !== -1) {
            await nextTick();
            if (fileDOMItems.value?.[errorIndex]) {
              fileDOMItems.value[errorIndex].scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "center"
              });
            }
          }
        }
      }
    };
    const sendCancelFilePick = () => {
      if (pendingBridgeRequest) {
        sendResponse({
          type: pendingBridgeRequest.type + "Failed",
          requestId: pendingBridgeRequest.requestId,
          data: {
            error: {
              code: 2,
              message: "Canceled by user"
            }
          }
        });
        pendingBridgeRequest = null;
        closeFilePickerPopup();
      }
    };
    const closeFilePickerPopup = () => {
      filePicker.value.isShowPopup = false;
      filePicker.value.isMultipleFiles = false;
      filePicker.value.isImagesOnly = false;
      isFilesUploading.value = false;
      selectedFiles.value = [];
    };
    const openFilePickerPopup = async () => {
      filePicker.value.isShowPopup = true;
    };
    const updateSelectedFiles = async () => {
      if (fileInput.value?.files) {
        for (let i = 0; i < fileInput.value.files.length; i++) {
          const file = fileInput.value.files[i];

          if (
            pendingBridgeRequest?.data &&
            (pendingBridgeRequest.data.type === 1 ||
              pendingBridgeRequest.type === "LifeMiniAppTakePicture")
          ) {
            if (
              imageExtensions
                .split(",")
                .some(ext => file.name.toLowerCase().includes(ext))
            ) {
              const base = await $service.getBase64(file);

              selectedFiles.value.push({
                name: file.name,
                error: false,
                warn: file.size > $store.state.settingsStore.maxServiceFileSize,
                file,
                base
              });
            }
          } else {
            const base = await $service.getBase64(file);

            selectedFiles.value.push({
              name: file.name,
              error: false,
              warn: file.size > $store.state.settingsStore.maxServiceFileSize,
              file,
              base
            });
          }
        }
        fileInput.value.value = "";
      }
    };
    const removeSelectedFile = (index: number) => {
      selectedFiles.value.splice(index, 1);
    };
    const previewCloseHandler = () => {
      previewImageList.value = [];
      previewPdf.value = false;
    };
    const downloadPreviewedFile = () => {
      const file = (previewImageList.value || [])[0];

      if (file?.base) {
        if (isBrowser.value) {
          const a = document.createElement("a");

          a.href = file.base;
          a.download = file.name;
          a.target = "_blank";
          a.click();
        } else {
          Electron().ipcRenderer.send("download", {
            url: file.base,
            name: file.name
          });
        }
      }
    };
    const requestAccess = () => {
      $store.commit("openFeedbackForm", {
        type: "request-access",
        context: app.value?.title
      });
    };
    const reportError = () => {
      $store.commit("openFeedbackForm", {
        type: "report-error",
        context: app.value?.title
      });
    };
    const dragStart = () => {
      isFileDragging.value = true;
      dragStopTimeout = setTimeout(() => {
        if (timeout === dragStopTimeout) isFileDragging.value = false;
      }, 100);
      const timeout = dragStopTimeout;
    };
    const dragDrop = (event: DragEvent) => {
      event.preventDefault();
      if (selectedFiles.value.length < maxFiles.value) {
        dragStopTimeout = null;
        if (fileInput.value && event.dataTransfer) {
          fileInput.value.files = event.dataTransfer.files;
        }
        updateSelectedFiles();
      }
      isFileDragging.value = false;
    };
    const preventDefaults = (e: Event) => {
      e.preventDefault();
    };
    const openSetUrlPopup = () => {
      urlText.value = url.value;
      isSetUrlPopupVisible.value = true;
    };
    const closeSetUrlPopup = () => {
      isSetUrlPopupVisible.value = false;
      urlText.value = "";
    };
    const setUrl = (clear: boolean) => {
      const original = ($store.state.serviceList || []).find(
        s => s.uuid === app.value?.uuid
      );

      if (clear && original) {
        closeSetUrlPopup();
        showNoAccessWindow.value = false;
        showErrorWindow.value = false;
        setApp(false, true);
        $store.commit("settingsStore/removeCustomService", original.uuid);

        return;
      }
      if (original && original.source === urlText.value.trim()) {
        if (isCustomService.value) {
          const service = $store.state.settingsStore.customServiceList.find(
            cs => cs.uuid === props.id
          );

          if (service) {
            closeSetUrlPopup();
            showNoAccessWindow.value = false;
            showErrorWindow.value = false;
            setApp(false, true);
            $store.commit("settingsStore/removeCustomService", service.uuid);
          }

          return;
        } else {
          closeSetUrlPopup();

          return;
        }
      } else if (urlText.value.trim() && app.value) {
        $store.commit("settingsStore/addCustomService", {
          description: app.value.description,
          source: urlText.value.trim(),
          title: app.value.title,
          uuid: app.value.uuid,
          widgetUrl: "",
          logoId: app.value.logoId,
          logoMime: app.value.logoMime,
          version: app.value.version
        });
      } else {
        const service = $store.state.settingsStore.customServiceList.find(
          cs => cs.uuid === props.id
        );

        if (service) {
          $store.commit("settingsStore/removeCustomService", service.uuid);
        }
      }
      closeSetUrlPopup();
      splashLoadState.value = SplashState.Visible;
      showNoAccessWindow.value = false;
      showErrorWindow.value = false;
      setApp(false, true);
    };
    const prettifySize = (size: number): string => {
      return $service.prettifyFileSize(size);
    };
    const sendResponse = (response: IBridgeResponse) => {
      if (iframe.value?.contentWindow && app.value) {
        iframe.value.contentWindow.postMessage(response, "*");
        if (devMode.value) {
          $service.log([
            `%c${new Date().toLocaleString()}: %c${app.value.title}%c got response %c${response.type}`,
            "color: initial;font-style: italic",
            "color: #49a1ff;font-weight: bold",
            "color: initial;font-weight: bold",
            response.type.startsWith("LifeSystem")
              ? "color: #e11b11;font-weight: bold"
              : "color: #ff8a0c;font-weight: bold",
            response.data
          ]);
        }
      }
    };
    const urlHandler = async (url: string, watch = false): Promise<boolean> => {
      if (
        url.match(linkRegexLocalhost) ||
        (app.value &&
          !watch &&
          url.replace(/\/$/, "").includes(app.value.source.replace(/\/$/, "")))
      ) {
        const urlItem = new URL(url);
        const isDynLink = $environmentService
          .environmentOptions()
          .dynLinkOrigins.some(dl => dl && urlItem.href.includes(dl));
        const isAppLink = urlItem.href.includes(
          $environmentService.environmentOptions().appLink
        );
        const isWebAppLink = urlItem.href.includes(
          $environmentService.environmentOptions().webAppLink
        );
        const appItem = ($store.state.serviceList || []).find(
          a =>
            !a.siteAsService &&
            urlItem.origin.includes(a.source.replace(/\/$/, "")) &&
            (a.uuid !== app.value?.uuid || !watch)
        );

        if (isDynLink) {
          const response = await $infra.dynLink.GetPayload(urlItem.href);
          const { type, appId, payload } =
            $service.getRouteFromAppLink(response);

          if (["user", "app"].includes(type)) {
            if (type === "app") {
              const isSameApp = appId === app.value?.uuid;

              if (isSameApp) {
                await $router.push({
                  name: "app",
                  params: {
                    id: appId
                  },
                  query: {
                    payload,
                    reload: "true"
                  }
                });
                await setApp(true, true);
              } else {
                void $store.dispatch("openApp", {
                  id: appId,
                  query: { payload },
                  reopen: true
                });
                setTimeout(() => {
                  void $router.push({
                    name: "app",
                    params: {
                      id: appId
                    },
                    query: {
                      payload
                    }
                  });
                }, 20);
              }
            } else {
              void $router.push({
                name: "user-page",
                params: {
                  id: appId
                },
                query: {
                  payload
                }
              });
            }
          }

          return false;
        } else if (isAppLink || isWebAppLink) {
          const { type, appId, payload } = $service.getRouteFromAppLink(url);

          if (["user", "app"].includes(type)) {
            if (type === "app") {
              const isSameApp = appId === app.value?.uuid;

              if (isSameApp) {
                await setApp(true, true);
              } else {
                await $router.push({
                  name: "app",
                  params: {
                    id: appId
                  },
                  query: {
                    payload,
                    reload: "true"
                  }
                });
                void $store.dispatch("openApp", {
                  id: appId,
                  query: { payload },
                  reopen: true
                });
                setTimeout(() => {
                  void $router.push({
                    name: "app",
                    params: {
                      id: appId
                    },
                    query: {
                      payload
                    }
                  });
                }, 20);
              }
            } else {
              void $router.push({
                name: "user-page",
                params: {
                  id: appId
                },
                query: {
                  payload
                }
              });
            }
          }
        } else if (appItem) {
          const payload = urlItem.pathname.replace(/^\//, "") + urlItem.search;

          void $store.dispatch("openApp", {
            id: appItem.uuid,
            query: { payload },
            reopen: true
          });
          setTimeout(() => {
            void $router.push({
              name: "app",
              params: {
                id: appItem.uuid
              },
              query: {
                payload
              }
            });
          }, 20);
          if (watch) {
            $store.commit("closeApp", app.value?.uuid);
          }
        } else if (
          url.match(linkRegexLocalhost) &&
          app.value &&
          urlItem.origin.includes(app.value.source.replace(/\/$/, ""))
        ) {
          const urlItem = new URL(url);

          if (
            urlItem.pathname &&
            (urlItem.pathname !== "/" || urlItem.search)
          ) {
            noReload = true;
            emit("updateQuery", {
              payload:
                urlItem.pathname.replace(/^\//, "") +
                (urlItem.search ? "?" + urlItem.search : "")
            });
          } else {
            noReload = true;
            emit("updateQuery", undefined);
          }
        } else if (url.match(linkRegexLocalhost)) {
          return true;
        }

        return false;
      }

      return true;
    };
    const unloadHandler = () => {
      emit("updateQuery", undefined);
      $router.push({ query: undefined });
    };
    const validateTheme = (data: {
      backgroundColor?: string;
      buttonsBackgroundColor?: string;
      buttonsIconColor?: string;
      buttonsDividerColor?: string;
      navigationIconBrightness?: number;
    }): string => {
      if (
        data.buttonsBackgroundColor ||
        data.buttonsIconColor ||
        data.buttonsDividerColor
      ) {
        if (
          data.buttonsBackgroundColor &&
          !hexColorRegex.test(data.buttonsBackgroundColor.toLowerCase())
        ) {
          return "Param `buttonsBackgroundColor` is invalid";
        }
        if (
          data.buttonsIconColor &&
          !hexColorRegex.test(data.buttonsIconColor.toLowerCase())
        ) {
          return "Param `buttonsIconColor` is invalid";
        }
        if (
          data.buttonsDividerColor &&
          !hexColorRegex.test(data.buttonsDividerColor.toLowerCase())
        ) {
          return "Param `buttonsDividerColor` is invalid";
        }
        buttonsBackgroundColor.value = data.buttonsBackgroundColor
          ? "#" + data.buttonsBackgroundColor
          : "";
        buttonsIconColor.value = data.buttonsIconColor
          ? "#" + data.buttonsIconColor
          : "";
        buttonsDividerColor.value = data.buttonsDividerColor
          ? "#" + data.buttonsDividerColor
          : "";

        return "";
      } else {
        return "Request has no supported styles for desktop TN Life";
      }
    };
    const closeEmailConfirmHandler = (close: boolean) => {
      if (pendingBridgeRequest) {
        if (close) {
          sendResponse({
            type: pendingBridgeRequest.type + "Failed",
            requestId: pendingBridgeRequest.requestId,
            data: {
              error: {
                code: 2,
                message: "Canceled by user"
              }
            }
          });
        } else if (app.value) {
          bridgeHandler(pendingBridgeRequest, app.value.source);
        }
      }
      pendingBridgeRequest = null;
    };

    return {
      url,
      app,
      iframe,
      optionsMenuItems,
      isOptionsMenuVisible,
      filePickerPopup,
      filePicker,
      fileInput,
      selectedFiles,
      maxFiles,
      isUploadFilesButtonDisabled,
      isFilesUploading,
      isFilesHaveErrors,
      fileDOMItems,
      imageExtensions,
      uploadLabel,
      previewImageList,
      previewPdf,
      splashLoadState,
      noAnimation,
      showNoAccessWindow,
      showErrorWindow,
      uploadText,
      isFileDragging,
      dragDropLabel,
      maxFilesLabel,
      isCustomService,
      uploadReleaseText,
      isSetUrlPopupVisible,
      setUrlPopup,
      urlText,
      devMode,
      filePickerText,
      isSomeFileTooBig,
      maxFileSize,
      tooBigFilesText,
      loadFilesErrorText,
      buttonsBackgroundColor,
      buttonsIconColor,
      buttonsDividerColor,
      isMobile,
      confirmPopup,
      isConfirmPopupVisible,
      SplashState,
      getFileExtension: $service.getFileExtension,
      closeEmailConfirmPopup,
      reportError,
      requestAccess,
      prettifySize,
      sendCatcher,
      closeService,
      reloadService,
      openOptionsMenu,
      optionSelectHandler,
      closeOptionsMenu,
      updateSelectedFiles,
      uploadFiles,
      sendCancelFilePick,
      removeSelectedFile,
      downloadPreviewedFile,
      previewCloseHandler,
      dragDrop,
      dragStart,
      closeSetUrlPopup,
      openSetUrlPopup,
      setUrl
    };
  }
});
